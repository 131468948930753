<template>
  <div class="accommodation_section">

    <section>
      <article class="stay_header">
        <div class="container h-100 d-flex align-items-end text-white">
          <div class="pb-3 h-100 d-flex justify-content-between flex-column">
            <div style="margin-top:100px;">
              <h1>Accommodation</h1>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item>Accommodation</el-breadcrumb-item>
              </el-breadcrumb>
            </div>

            <div class="dining_introduction">
              <p class="trekkers_word_style">
                <small>TREKKER'S TAVERN COTTAGES</small>
              </p>
              <h2 style="font-weight: 600; font-size: 2em">
                Lavish and contemporary comforts and a classical architectural charm
              </h2>
              <p style="font-weight: 300">
                Trekker's Tavern Cottages boasts an array of assorted accommodations
                offering the discerning traveler a variety of choice accommodation
                to suit their lifestyle, comfort and needs. Whether you travel alone or as a group.
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="rooms">
        <div class="container">
          <section>
            <div
              class="rooms_grid"
              data-aos="fade-up"
              data-aos-duration="2000"
              v-for="room in rooms"
              :key="room.id"
            >
              <div class="rooms_image">
                <el-carousel height="260px">
                  <el-carousel-item
                    v-for="image in room.images"
                    :key="image"
                  >
                    <img
                      :src="image"
                      class="d-block w-100"
                      alt="..."
                    />
                  </el-carousel-item>
                </el-carousel>
              </div>

              <div class="">
                <div>
                  <h2 style="font-size: 1.2em; font-weight: 600">{{ room.title }}</h2>
                  <p style="color: grey;">
                    <small>
                      {{ room.description }}
                    </small>
                  </p>
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <h3 style="font-size: 1em; font-weight: 600">SERVICES</h3>
                      <ul style="color: grey;">
                        <li>
                          <small>
                            <i
                              class="fas fa-shower"
                              style="color: #0E763C; padding-right:5px"
                            ></i>
                            Amenities in the bathroom
                          </small>
                        </li>
                        <li>
                          <small>
                            <i
                              class="fas fa-wifi"
                              style="color: #0E763C; padding-right:5px"
                            ></i>Free Wi-Fi
                            internet
                          </small>
                        </li>
                        <li>
                          <small>
                            <i
                              class="fas fa-tv"
                              style="color: #0E763C; padding-right:5px"
                            ></i>
                            Smart TV
                          </small>
                        </li>
                        <li>
                          <small>
                            <i
                              class="el-icon-phone-outline"
                              style="color: #0E763C; padding-right:5px"
                            ></i>
                            Room service
                          </small>
                        </li>
                      </ul>
                    </div>
                    <div style="
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                      ">
                      <p></p>
                      <button
                        class="btn btn-brown btn-sm"
                        @click="accommodationDetail(room.id)"
                      >
                        BOOK NOW <span class="el-icon-d-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      table_drawer: false,
      direction: "rtl",

      rooms: [
        {
          id: "1",
          room_code: "standard",
          title: "Standard Cottage Rooms",
          room_price: 250,
          description:
            "Ideal for Families or long staying guests, the Super Executive Cottage features three bed rooms en-suite, a cosy lounge, separate dining area and a kitchen.",
          images: [
            require("../assets/images/cottages/20220608_151529.jpg"),
            require("../assets/images/cottages/IMG-20191001-WA0133.jpg"),
            require("../assets/images/cottages/20220608_165115.jpg"),
          ],
        },
        {
          id: "3",
          title: "honeymoon",
          room_code: "Honeymoon Cottage Rooms",
          room_price: 80,
          description:
            "Executive Rooms offer large king size beds and garden views from the private balconies. Ideal for business stays, this room category features an expansive workspace and a relaxation area with a comfortable couch and coffee table.",
          images: [
            require("../assets/images/cottages/20220608_164704.jpg"),
            require("../assets/images/cottages/20220608_174802.jpg"),
            require("../assets/images/cottages/new/5.jpeg"),
          ],
        },
        {
          id: "2",
          room_code: "twin_family",
          title: "Twin Family Cottage Rooms",
          room_price: 60,
          description:
            "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
          images: [
            require("../assets/images/cottages/20220608_164704.jpg"),
            require("../assets/images/cottages/new/9.jpeg"),
            require("../assets/images/cottages/20220608_165530.jpg"),
          ],
        },
        {
          id: "2",
          room_code: "family",
          title: "Family Cottage House",
          room_price: 60,
          description:
            "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
          images: [
            require("../assets/images/cottages/20220608_133842.jpg"),
            require("../assets/images/cottages/20220608_174802.jpg"),
            require("../assets/images/cottages/20220608_165442.jpg"),
          ],
        },
      ],
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #fef9f6;-->
<style scoped>
.stay_header {
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url("../assets/images/cottages/20220608_174802.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.stay_header h1 {
  font-size: 1.2em;
}
.accommodation_section {
  /* background-image: linear-gradient(
      rgba(254, 249, 246, 0.9),
      rgba(254, 249, 246, 1)
    ),
    url("../assets/images/cottages/20220608_101144.jpg"); */
}
.trekkers_word_style {
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}
.dining_introduction {
  width: 60%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.dining_grid > div .dining_grid_image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

.btn-brown {
  background-color: #0e763c;
  color: white;
  width: 200px;
}
.btn-view-more {
  background-color: white;
  border: 1px solid #0e763c;
  width: 250px;
}

.rooms {
  padding-top: 100px;
  padding-bottom: 100px;
}

.rooms_grid {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  border-radius: 5px;
}

.rooms_grid > div {
  text-align: left;
  padding: 20px;
  border-radius: 5px;
}

.rooms_grid > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.rooms_grid .rooms_image img {
  height: 260px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.rooms_grid ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}
</style>
